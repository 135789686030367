<template lang="pug">
  div
    v-row
      v-col(cols="3")
        create-pr-detail(
          v-model="details"
        )
      v-col(cols="9")
        table-pr-detail(
          :items="details"
        )
</template>
<script>
export default {
  name: 'PurchaseRequestDetail',
  props: ['value'],
  components: {
    createPrDetail: () => import('./Create'),
    tablePrDetail: () => import('./Table'),
  },
  data () {
    return {
      details: [],
    }
  },
  watch: {
    details () {
      this.$emit('input', this.details)
    },
  },
}
</script>